import React from "react";
import "../static/maintenance.css";
import logo from "../images/logo_h_color_white.svg";

const MaintenancePage = () => {
  return (
    <div className="maintenance-container">
      <img src={logo} alt="logo" className="maintenance-logo" />
      <h1 className="maintenance-heading">Under Maintenance</h1>
      <p className="maintenance-text">
        We are currently performing maintenance on our website. Please check
        back later.
      </p>
    </div>
  );
};

export default MaintenancePage;
